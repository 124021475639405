*{
    --background: #1A1A1A;
    --contrast: #151515;
    --contrastRGB: 21,21,21;
    --contrastAdd: #121212;

    --red: #FF453A;
    --green: #32D74B;
    --yellow: #FFD60A;
    --accent: #fff;
    --accentRGB: 255,255,255;
    --accentHover: #999999;

    --backgroundRGB: 26, 26, 26;

    --redRGB: 255, 69, 58;
    --greenRGB: 50, 215, 75;
    --yellowRGB: 255, 214, 10;

    --white: 255,255,255;
    --black: 0,0,0;
    --whiteHEX: #fff;
    --blackHEX: #000;

    /* assoc files types */

    --file: #5856D6;
    --file-img: #FF9500;
    --file-vid: #AF52DE;
    --file-aud: #007AFF;
}