@import "./schemas/dark.css";
@import "./schemas/fonts.css";
@import "./schemas/anims.css";
@import "./schemas/adaptive.css";

*{
    --basePadd: 18%;
}

#root{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

html,
body{
    background: linear-gradient(90deg, #0B0B0B 0.01%, #0F0F0F 99.99%);
    font-family: 'Inter', serif;
    cursor: default;
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: hidden;
}

.base-wrap{
    padding: 30px var(--basePadd);
}

.main-sm{
    background-color: var(--contrast);
    display: flex;
    border-radius: 20px;
    width: 40%;
    height: 55%;
    border: 1px solid rgba(var(--white), .08);
}

.semi-left{
    background-color: var(--contrastAdd);
    border-radius: 20px 0 0 20px;
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: flex-end;
    padding: 40px;
    border-right: 1px solid rgba(var(--white), .08);
}

.semi-right{
    border-radius: 0 20px 20px 0;
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: space-around;
    padding: 40px;
}

.brand{
    display: flex;
    flex-direction: column;
}

.brand > h3{
    font-family: 'Exo 2 Medium', serif;
    color: var(--accent);
    margin-block-start: 0;
    margin-block-end: 0;
    letter-spacing: 2px;
    font-size: 16px;
}

.brand > h5{
    color: rgba(var(--white), .4);
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
    letter-spacing: 2px;
    font-size: 12px;
}

fs{
    color: var(--accent);
}

.fields{
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.info-pan{
    display: flex;
    flex-direction: column;
}

.info-pan > h1{
    font-size: 18px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
    color: var(--accent);
    letter-spacing: 2px;
}

.info-pan > h5{
    font-size: 13px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
    color: rgba(var(--white), .3);
}

.info-pan > h5 > a{
    font-size: 13px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
    color: var(--accent);
    cursor: pointer;
    transition: .35s;
    text-decoration: none;
}

.info-pan > h5 > a:hover{
    color: rgba(var(--white), .7);
}

.field{
    background-color: transparent;
    border: none;
    color: var(--accent);
    transition: .45s;
    outline: none;
    padding: 10px 10px 10px 0;
    font-size: 14px;
    border-bottom: 1px solid rgba(var(--white), .3);
}

.field:focus{
    border-color: rgba(var(--white), 1);
    color: var(--accent);
}

.field::placeholder{
    color: rgba(var(--white), .3);
}

.btn{
    font-size: 14px;
    border-radius: 10px;
    transition: .45s;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 10px;
}

.add-text{
    margin-block-start: 0;
    margin-block-end: 0;
    color: rgba(var(--accentRGB), .45);
    font-weight: 400;
    letter-spacing: 1.5px;
    font-size: 13px;
}

.files-table{
    display: flex;
    align-items: center;
    align-content: center;
    gap: 35px;
}

.files-table > h3{
    color: rgba(var(--accentRGB), .35) !important;
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 12px;
    font-weight: 400;
}

.file-lite-card{
    display: flex;
    align-items: center;
    align-content: center;
    cursor: pointer;
    transition: .35s;
    border-radius: 8px;
    gap: 35px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.fdesc{
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 10px;
    font-weight: 400;
    color: rgba(var(--accentRGB), .45);
    text-align: center;
}

.dism{
    display: flex;
}

.lite-prev{
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 8px;
}

.fname{
    display: flex;
    align-items: center;
    color: var(--accent);
    font-size: 14px;
    font-weight: 400;
}

.fname > h6{
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 10px;
    font-weight: 400;
    color: rgba(var(--white), .45);
}

.cards-list{
    display: flex;
}

.info-card{
    padding: 20px;
    border-radius: 8px;
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
}

.info-card > h4{
    color: rgba(var(--white), .45);
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 13px;
    font-weight: 400;
}

.info-card > h1{
    color: var(--whiteHEX);
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.5px;
}

.files-list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.filedata{
    display: flex;
    flex-direction: column;
    transition: .35s;
    border-radius: 4px;
    transition: .35s;
    cursor: pointer;
    width: 24%;
}

.filedata-sel{
    transform: scale(.95);
    opacity: .6;
}

.no-prev > p{
    display: block !important;
}

.files-row{
    display: flex;

}

.row-justify{
    justify-content: space-between;
}

.row-start{
    justify-content: flex-start;
}

.files-group{
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: 400;
    color: var(--whiteHEX);
    display: flex;
    gap: 5px;
}

.file-counter{
    font-size: 12px;
    color: rgba(var(--white), .35);
}

.pfile{
    background-color: var(--file) !important;
}

.paudio{
    background-color: var(--file-aud) !important;
}

.pvid{
    background-color: var(--file-vid) !important;
}

.pimg{
    background-color: var(--file-img) !important;
}

.file-prev{
    border-radius: 6px;
    max-height: 130px;
    min-height: 130px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: var(--background);
    background-size: cover;
    background-position: center;
}

.file-prev > p{
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 400;
    color: var(--whiteHEX);
    background-color: var(--file);
    padding: 8px;
    border-radius: 4px;
    display: none;
}

.fileinfo{
    display: flex;
    flex-direction: column;
    padding: 15px;
    padding-left: 0;
    padding-right: 0;
}

.fileinfo > h1{
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 14px;
    letter-spacing: 1.5px;
    font-weight: 400;
    color: var(--whiteHEX);
}

.fileinfo > h3{
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 11px;
    letter-spacing: .5px;
    font-weight: 400;
    color: rgba(var(--white), .35);
}

.dist-all{
    display: flex;
    flex-direction: column;
}

.dist-all > svg > path{
    fill: var(--whiteHEX);
}

.dist-all:first-child{
    margin-top: 30px;
}

.dist-all > p{
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 13px;
    font-weight: 400;
    color: var(--whiteHEX);
    opacity: .45;
}

.main-btn{
    background-color: var(--accent);
    color: var(--contrast);
}

.main-btn > svg{
    stroke: var(--contrast);
}

.main-btn:hover{
    background-color: var(--background);
    color: var(--accent);
}

.ctr-btns{
    width: -webkit-fill-available;
    display: flex;
}

.accent-btn{
    outline: none;
    border: none;
    background-color: var(--accent);
    color: white;
    font-size: 13px;
    border-radius: 7px;
    cursor: pointer;
    transition: all .35s ease;
    display: flex;
    gap: 10px;
    padding: 10px 20px;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.toast{
    position: absolute;
    bottom: 50px;
    right: 50px;
    background-color: rgba(var(--white), .03);
    padding: 15px 30px;
    border: 1px solid rgba(var(--white), .1);
    font-size: 13px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
    color: white;
    border-radius: 7px;
    cursor: pointer;
    transition: .35s;
}

.white-txt{
    color: var(--whiteHEX) !important;
    letter-spacing: 2px !important;
    font-weight: 400 !important;
    font-size: 18px !important;
}

.btn-disabled{
    background-color: var(--background) !important;
    color: var(--whiteHEX) !important;
}

.err-code{
    background-color: var(--red);
    color: var(--whiteHEX);
    font-size: 16px;
    font-weight: 400;
    padding: 5px;
}

.toast-err{
    background-color: rgba(var(--redRGB), 0.1) !important;
    border-color: rgba(var(--redRGB), .2) !important;
    color: var(--red) !important;
}

.edit:focus{
    border-color: var(--accent);
}

.center-full{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.proj{
    display: flex;
}

.proj > img{
    opacity: 0.5;
    transition: .35s;
    cursor: pointer;
}

.proj > img:hover{
    opacity: 1;
}

.right-panel{
    display: flex;
    flex-direction: column;
    padding: 5%;
    justify-content: space-between;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
}

.right-panel > h1{
    color: var(--whiteHEX);
    font-weight: 500;
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 20px;
}

.user-profile{
    display: flex;
    border-radius: 20px;
    width: 60%;
    height: 80%;
    flex-direction: column;
    background-color: var(--contrast);
    border: 1px solid rgba(var(--white), .08);
}

.left-prof{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 3%;
    padding-right: 0 !important;
}

.lfd{
    display: flex;
    flex-direction: column;
}

.user-prof-wrap{
    display: flex;
    height: -webkit-fill-available;
}

.deauth{
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--red);
    transition: .35s;
    cursor: pointer;
}

.counter{
    font-size: 10px;
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 400;
    position: absolute;
    background-color: var(--red);
    border-radius: 10000px;
    width: 8px;
    height: 8px;
    display: flex;
    color: var(--whiteHEX) !important;
    align-items: center;
    justify-content: center;
    right: -4px;
    border: 4px solid var(--contrast);
    top: -4px;
}

.left-smd{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    cursor: pointer;
    transition: .35s;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--background);
    position: relative;
}

.left-smd > svg > g,
.left-smd > svg > path{
    stroke: rgba(var(--white), .45);
    transition: .35s;
}

.left-smd:hover > svg > g,
.left-smd:hover > svg > path{
    stroke: rgba(var(--white), .85);
    transition: .35s;
}

.left-smd > h4{
    font-size: 0;
    background-color: var(--red);
    margin-block-start: 0;
    margin-block-end: 0;
    width: 8px;
    height: 8px;
    border-radius: 100px;
}

.left-smd:hover{
    color: rgba(var(--white), .85);
    background-color: rgba(var(--white), .05);
    border-radius: 8px;
}

.scene{
    width: -webkit-fill-available;
    overflow: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    display: flex;
}

.scene::-webkit-scrollbar{
    width: 3px;
    background-color: transparent;
}

.scene::-webkit-scrollbar-thumb{
    background-color: transparent;
}

.scene-wrap{
    display: flex;
    flex-direction: column;
    padding: 4% 5% 5%;
    width: -webkit-fill-available;
}

.scene-wrap > h1{
    font-size: 20px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
    color: rgba(var(--white), .65);
}

b{
    color: var(--whiteHEX);
    font-weight: 500 !important;
    font-family: 'Inter Medium', serif !important;
}

.smd-sel{
    background-color: var(--accent) !important;
    box-shadow: 0 0 25px rgba(var(--accentRGB), .5) !important;
}

.smd-sel > .counter{
    border-color: var(--contrast) !important;
}

.smd-sel>svg>g,
.smd-sel>svg>path {
    stroke: var(--background) !important;
}


.user{
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    align-items: center;
    width: -webkit-fill-available;
}

#DAY_PART{
    font-family: 'Inter Light', serif !important;
    font-weight: 300 !important;
}

.uava{
    background-color: rgba(var(--white), .05);
    width: 45px;
    height: 45px;
    border-radius: 10px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.uava > img{
    border-radius: 10px;
    background-color: rgba(var(--white), .05);
    width: 45px;
    height: 45px;
    object-fit: cover;
}

.profile-content{
    display: flex;
    align-items: flex-start;
}

.prof-data > h3{
    margin-block-start: 0;
    margin-block-end: 0;
    color: var(--whiteHEX);
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
}

.prof-data > h5{
    margin-block-start: 0;
    margin-block-end: 0;
    color: rgba(var(--white), .35);
    font-size: 13px;
    font-weight: 400;
}

.profile-ava-full{
    border-radius: 10px;
    background-color: rgba(var(--white), .05);
    width: 150px;
    height: 150px;
    object-fit: cover;
    transition: all .35s;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.prof-ava-wrap > p{
    font-size: 29px !important;
}

.prof-ava-wrap{
    position: relative;
    cursor: pointer;
    transition: all .35s;
}

.prof-ava-wrap:hover > svg{
    opacity: 1;
    transition: all .35s;
}

.prof-ava-wrap:hover > p{
    opacity: .4;
    transition: all .35s;
}

.prof-ava-wrap:hover > img{
    opacity: .4;
    transition: all .35s;
}

.prof-ava-wrap > svg{
    right: 50%;
    bottom: 50%;
    transform: translate(50%,50%);
    position: absolute;
    opacity: 0;
    transition: all .35s;
}

.prof-ava-wrap > svg > path,
.prof-ava-wrap > svg > g{
    stroke: var(--accent);
}

.user-data{
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.user-data > h4{
    color: var(--whiteHEX);
    font-size: 15px;
    font-weight: 500;
    margin-block-start: 0;
    margin-block-end: 0;
}

.user-data > h6{
    color: rgba(var(--white), .45);
    font-size: 13px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
}

.cards{
    display: flex;

}

.wallet{
    width: 100%;
}

.cards > a{
    display: flex;
    width: 20%;
    cursor: pointer;
    text-decoration: none;
}

.balance-rw{
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.back-cl{
    background-color: var(--contrastAdd);
    border-radius: 8px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 90%;
}

a{
    text-decoration: none !important;
}

.back-cl > p{
    color: var(--whiteHEX);
    font-size: 13px;
    margin-block-start: 0;
    margin-block-end: 0;
    width: 70%;
}

.bal-semi{
    width: 50%;
    display: flex;
    align-items: stretch;
    align-content: stretch;
    justify-content: space-between;
    flex-direction: column;
    padding: 25px;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: var(--background);
    border-radius: 8px;
}

.inter-wallet{
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 40px;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='500' height='500' preserveAspectRatio='none' viewBox='0 0 500 500'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1041%26quot%3b)' fill='none'%3e%3crect width='500' height='500' x='0' y='0' fill='rgba(43%2c 43%2c 43%2c 1)'%3e%3c/rect%3e%3cpath d='M0%2c212.146C38.958%2c207.362%2c73.03%2c185.695%2c103.215%2c160.606C130.747%2c137.722%2c146.489%2c106.035%2c164.727%2c75.228C185.252%2c40.557%2c215.47%2c9.108%2c216.743%2c-31.163C218.098%2c-74.009%2c203.644%2c-120.165%2c171.812%2c-148.876C140.739%2c-176.903%2c94.226%2c-174.748%2c52.618%2c-179.199C17.596%2c-182.946%2c-15.591%2c-175.448%2c-50.704%2c-172.681C-96.503%2c-169.072%2c-155.551%2c-195.57%2c-185.18%2c-160.46C-214.943%2c-125.191%2c-179.788%2c-71.104%2c-175.176%2c-25.186C-171.778%2c8.642%2c-170.03%2c40.874%2c-161.647%2c73.822C-151.466%2c113.833%2c-152.269%2c161.282%2c-120.9%2c188.125C-89.037%2c215.391%2c-41.624%2c217.257%2c0%2c212.146' fill='%231c1c1c'%3e%3c/path%3e%3cpath d='M500 726.7090000000001C541.699 725.936 571.836 688.444 604.201 662.14 633.156 638.607 658.5029999999999 613.003 678.592 581.56 701.487 545.725 728.519 509.626 728.811 467.102 729.121 422.024 708.1279999999999 379.23900000000003 680.226 343.83299999999997 651.482 307.358 613.414 278.54499999999996 569.15 264.497 524.322 250.269 475.598 250.042 430.911 264.706 387.01099999999997 279.11199999999997 352.185 310.78200000000004 321.865 345.645 291.5 380.55899999999997 263.519 419.253 256.699 465.019 249.861 510.899 258.103 560.196 284.172 598.565 308.703 634.67 355.753 643.766 393.286 666.05 429.196 687.371 458.245 727.483 500 726.7090000000001' fill='%233a3a3a'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1041'%3e%3crect width='500' height='500' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
}

.inter-wallet > .btn{
    padding-left: 15px;
    padding-right: 15px;
}

.inter-wallet > h1{
    color: var(--whiteHEX);
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 18px;
    font-weight: 400;

}

.wallet > p{
    color: rgba(var(--white), .45);
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 12px;
    font-weight: 400;
}

.wallet>h1 {
    color: var(--whiteHEX);
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 18px;
    font-weight: 400;
}

.base-heading{
    color: var(--whiteHEX) !important;
    font-size: 18px !important;
    font-weight: 400;
    letter-spacing: 2px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.base-desc{
    color: rgba(var(--white), .35) !important;
    font-size: 12px !important;
    font-weight: 400;
    letter-spacing: 2px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.service-list{
    display: flex;
    flex-direction: column;
}

.service-row{
    display: flex;
    
}

.serv-card{
    border-radius: 8px;
    padding: 20px 30px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    transition: .35s;
    background-color: var(--background);
}

.serv-card:hover{
    box-shadow: 0 0 25px rgba(var(--accentRGB), .1);
}

.smd-off{
    opacity: .25 !important;
    background-color: transparent !important;
    cursor: default !important;
}

.serv-hd{
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.serv-hd > h1{
    color: var(--whiteHEX);
    font-size: 14px;
    font-weight: 500;
    margin-block-start: 0;
    margin-block-end: 0;
}

.a{
    text-decoration: none;
    color: var(--accent);
    cursor: pointer;
    transition: .35s;
}

.a:hover{
    color: var(--accentHover);
}

.serv-des{
    display: flex;
    flex-direction: column;
}

.not-con{
    color: rgba(var(--white), .35);
    font-weight: 400;
    cursor: pointer;
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 12px;
}

.serv-hd > p{
    color: rgba(var(--white), .45);
    font-size: 12px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
    max-width: 300px;
}

bsd{
    color: var(--whiteHEX);
    font-weight: 600;
    text-decoration: underline;
}

.card{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 19px 30px;
    transition: .35s;
    background-color: var(--background);
}

.card:hover{
    background-color: rgba(var(--white), .03);
}

.app-descs{
    color: rgba(var(--white), .35);
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 12px;
    font-weight: 400;
}

.await-text{
    color: var(--yellow) !important;
}

.paid-text{
    color: var(--green) !important;
}

.transaction{
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(var(--white), .08);
    padding: 25px;
    gap: 20px;
}

.transaction > h5{
    color: rgba(var(--white), .45);
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 400;
    font-size: 12px;
}

.transaction > h3{
    color: rgba(var(--white), .45);
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    align-content: center;
    gap: 5px;
}

.transaction > h3 > h3{
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 600;
    font-size: 12px;
}

.transaction > h1{
    color: var(--whiteHEX);
    font-size: 24px;
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 500;
}

.transaction:not(.transaction:last-child){
    border-bottom: none !important;
}

.transaction:last-child{
    border-radius: 0 0 8px 8px;
}

.transaction:first-child{
    border-radius: 8px 8px 0 0;
}

.user-no-ava{
    color: var(--whiteHEX);
    text-align: center;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
}

.all-files-bar{
    display: flex;
    flex-direction: column;
    z-index: 9999;
    position: sticky;
    top: 0;
    transition: .35s all;
    background-color: rgba(var(--contrastRGB), .8);
    backdrop-filter: blur(10px);
    padding-top: 25px;
    padding-bottom: 25px;
}

.storage-card{
    display: flex;
    gap: 25px;
    background-color: var(--background);
    border-radius: 10px;
    cursor: pointer;
    padding: 20px;
    transition: .35s;
    width: 27%;
}

.storage-card:hover{
    background-color: rgba(var(--white), .1);
}

.storage-info{
    display: flex;
    flex-direction: column;
}

.state-ok{
    color: var(--green) !important;
}

.state-warn{
    color: var(--yellow) !important;
}

.state-err{
    color: var(--red) !important;
}

.storage-info > h1{
    letter-spacing: 1.5px;
    font-size: 15px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
    color: var(--accent);
}

.storage-info > h3{
    font-size: 12px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
    color: rgba(var(--accentRGB), .45);
}

.storage-card > svg{
    background-color: var(--accent);
    padding: 10px;
    border-radius: 8px;
    width: 24px;
    height: 24px;
}

.storages-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
}

.com-bar{
    display: flex;
}

.dsm-wrap{
    display: flex;
}

.dsm-wrap > .btn{
    font-size: 12px !important;
}

.dsm-wrap > .search-edit{
    font-size: 12px !important;
}

.filter-type{
    display: flex;
    gap: 15px;
    background-color: var(--background);
    border-radius: 10px;
    cursor: pointer;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    align-content: center;
    justify-content: center;
    transition: .35s;
}

.filter-type:hover{
    background-color: rgba(var(--accentRGB), .1);
}

.filter-type > h3{
    margin-block-start: 0;
    margin-block-end: 0;
    color: var(--accent);
    font-size: 12px;
    font-weight: 400;
}

.type-file > svg > path,
.type-file > svg > g{
    stroke: var(--file);
}

.type-image > svg > path,
.type-image > svg > g{
    stroke: var(--file-img);
}

.type-video > svg > path,
.type-video > svg > g{
    stroke: var(--file-vid);
}

.type-audio > svg > path,
.type-audio > svg > g{
    stroke: var(--file-aud);
}

.search-edit{
    background-color: var(--background);
    border-radius: 10px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    transition: .35s;
    outline: none;
    border: 1.5px solid transparent;
    color: var(--accent);
    width: -webkit-fill-available;
}

.search-edit:focus{
    border-color: var(--accent);
}

.calc{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
}

.selector{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

.selector-div{
    display: flex;
    gap: 10px;
}

.selector > h5{
    margin-block-start: 0;
    margin-block-end: 0;
    color: var(--whiteHEX);
    font-size: 12px;
    font-weight: 400;
}

.header-wrap{
    display: flex;
}

.header-wrap > svg{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.header-wrap > svg > path{
    stroke: rgba(var(--white), .4);
}

.tasksblock{
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
}

.dwntask{
    display: flex;
    border-radius: 8px;
    background-color: rgba(var(--white), .02);
    padding: 20px;
    align-items: center;
    gap: 50px;
}

.dwntask-group{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.textanim{
    animation: text 0.35s cubic-bezier(0.300, 0.575, 0.565, 1.000) both;
}

@keyframes text {
    0%{
        filter: blur(2px);
    }

    100%{
        filter: blur(0);
    }
}

.dwntask-group > p{
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 11px;
    font-weight: 400;
    color: var(--whiteHEX);
}

.dwntask-group > svg > g,
.dwntask-group > svg > path{
    stroke: var(--whiteHEX);
}

.dwntask > .dwntask-group > h3{
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 11px;
    font-weight: 400;
    color: var(--contrast);
    background-color: var(--whiteHEX);
    border-radius: 6px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.dwntask > .dwntask-group > div{
    display: flex;
    flex-direction: column;
}

.dwntask > .dwntask-group > div > h1{
    color: var(--whiteHEX);
    font-size: 15px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
    letter-spacing: 1.5px;
}

.err-svg > path{
    fill: var(--red);
}

.dwntask > .dwntask-group > div > div > h4{
    color: rgba(var(--white), .4);
    font-size: 12px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
}

.selector > .selector-div > .var-sl{
    background-color: var(--background);
    color: var(--whiteHEX);
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
    padding: 8px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
}

.sel-edit{
    outline: none;
    border: 1px solid transparent;
    transition: .35s;
    background-color: var(--background);
    color: var(--whiteHEX);
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
    padding: 8px;
    padding-left: 15px;
    padding-right: 15px;
}

.sel-edit:focus{
    border-color: rgba(var(--accentRGB), .25);
}

.sl-sel{
    background-color: var(--accent) !important;
    color: var(--background) !important;
}

.gap-2 {
    gap: 2px;
}

.gap-3 {
    gap: 3px;
}

.gap-5 {
    gap: 5px;
}

.gap-10{
    gap: 10px;
}

.gap-15{
    gap: 15px;
}

.gap-20{
    gap: 20px;
}

.gap-25 {
    gap: 25px;
}

.gap-30 {
    gap: 30px;
}

.gap-35 {
    gap: 35px;
}

.com-row-right{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-content: flex-end;
}

.com-col-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
}

.com-row-right-center{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}

.com-col-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
}

.com-col-stretch {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
}

.com-col-center{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.base-wrap{
    display: flex;

}

