.fade-in{
    animation: fade-in .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-out {
    animation: fade-out .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes rotation-base {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes color-change-5x {
    0% {
        border-color: #19dcea;
    }

    25% {
        border-color: #b22cff;
    }

    50% {
        border-color: #ea2222;
    }

    75% {
        border-color: #f5be10;
    }

    100% {
        border-color: #3bd80d;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes rotation-com {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.fade-in-top {
    -webkit-animation: fade-in-top 0.45s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-top 0.45s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-out-top {
    -webkit-animation: fade-out-top 0.35s cubic-bezier(0.300, 0.575, 0.565, 1.000) both;
    animation: fade-out-top 0.35s cubic-bezier(0.300, 0.575, 0.565, 1.000) both;
}

.fade-in-right {
    -webkit-animation: fade-in-right 0.4s cubic-bezier(0.300, 0.575, 0.565, 1.000) both;
    animation: fade-in-right 0.4s cubic-bezier(0.300, 0.575, 0.565, 1.000) both;
}

.fade-out-right {
    -webkit-animation: fade-out-right 0.4s cubic-bezier(0.300, 0.575, 0.565, 1.000) both;
    animation: fade-out-right 0.4s cubic-bezier(0.300, 0.575, 0.565, 1.000) both;
}

.fade-in-left {
    -webkit-animation: fade-in-left 0.55s cubic-bezier(0.300, 0.575, 0.565, 1.000) both;
    animation: fade-in-left 0.55s cubic-bezier(0.300, 0.575, 0.565, 1.000) both;
}

.fade-in-left-ping {
    animation: fade-in-leftf 0.45s cubic-bezier(0.300, 0.575, 0.565, 1.000) both;
}

.slide-out-top {
    -webkit-animation: slide-out-top 0.3s cubic-bezier(0.350, 0.085, 0.680, 0.530) both;
    animation: slide-out-top 0.3s cubic-bezier(0.350, 0.085, 0.680, 0.530) both;
}

.slide-in-bottom {
    animation: slide-in-bottom 0.2s cubic-bezier(0.300, 0.575, 0.565, 1.000) both;
}

.slide-out-right {
    -webkit-animation: slide-out-right 0.3s cubic-bezier(0.350, 0.085, 0.680, 0.530) both;
    animation: slide-out-right 0.3s cubic-bezier(0.350, 0.085, 0.680, 0.530) both;
}

.fade-out {
    -webkit-animation: fade-out 0.2s cubic-bezier(0.350, 0.085, 0.680, 0.530) both;
    animation: fade-out 0.2s cubic-bezier(0.350, 0.085, 0.680, 0.530) both;
}

.fade-in {
    -webkit-animation: fade-in 0.2s cubic-bezier(0.350, 0.085, 0.680, 0.530) both;
    animation: fade-in 0.2s cubic-bezier(0.350, 0.085, 0.680, 0.530) both;
}

.fade-in-con {
    animation: fade-in 0.1s cubic-bezier(0.350, 0.085, 0.680, 0.530) both;
}

.fade-in-bottom {
    animation: fade-in-bottom  .35s cubic-bezier(0.300, 0.575, 0.565, 1.000) .5s both;
}

.fade-out-top {
    animation: fade-out-top 0.2s cubic-bezier(0.320, 0.085, 0.680, 0.530) both;
}

.scale-in{
    animation: scale-in 0.25s cubic-bezier(0.300, 0.575, 0.565, 1.000) both;
}

@keyframes scale-in {
    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}

.loadersx {
    width: 30px;
    height: 30px;
    border: 3px solid rgba(255, 255, 255, 0.35);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotationx 1s linear infinite;
}

@keyframes rotationx {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.modal-load>g {
    stroke-width: 1px;

    animation: color-change-5x 5s linear infinite alternate both;
}

.color-change {
    animation: color-change-5x 10s linear infinite alternate both;
}

.selc-el {
    animation: scale-low 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

.shake-lr {
    animation: shake-lr 0.7s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@keyframes scale-low {
    0% {
        scale: 1;
    }

    100% {
        scale: 0.9;
    }
}

@keyframes shake-horizontal {

    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    20%,
    40%,
    60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }

    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }

    90% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }
}

@keyframes shake-lr {

    0%,
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    10% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }

    20%,
    40%,
    60% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    30%,
    50%,
    70% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }

    80% {
        -webkit-transform: rotate(-8deg);
        transform: rotate(-8deg);
    }

    90% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
}

.context-menu-wrap {
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px;
    background-color: rgba(40, 40, 40, 0.90);
    backdrop-filter: blur(20px);
    border-radius: 8px;
    position: absolute;
    z-index: 1000;
    min-width: 220px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
}

.con-f>path {
    fill: var(--red);
}

.cmi {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: rgba(255, 255, 255, 0.35);
    font-size: 13px;
    cursor: pointer;
    transition: 0.35s;
    display: flex;
    align-items: center;
    align-content: center;
    gap: 15px;
}

.cmi:hover {
    color: white;
}

@keyframes color-change-5x {
    0% {
        background-color: #19dcea;
    }

    25% {
        background-color: #b22cff;
    }

    50% {
        background-color: #ea2222;
    }

    75% {
        background-color: #f5be10;
    }

    100% {
        background-color: #3bd80d;
    }
}

@-webkit-keyframes fade-out-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }
}

@keyframes fade-out-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }
}

@keyframes fade-in-bottom {
    0% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes loading {
    0% {
        background-color: rgba(255, 255, 255, 0.01);
    }

    25% {
        background-color: rgba(255, 255, 255, 0.02);
    }

    50% {
        background-color: rgba(255, 255, 255, 0.04);
    }

    75% {
        background-color: rgba(255, 255, 255, 0.02);
    }

    100% {
        background-color: rgba(255, 255, 255, 0.01);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes slide-in-bottom {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-15px);
    }
}

@-webkit-keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes fade-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }
}

@keyframes fade-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }
}

@keyframes fade-in-leftf {
    0% {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in-left {
    0% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
        opacity: 0;
    }
}

@keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
        opacity: 0;
    }
}

@-webkit-keyframes slide-out-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }
}

@keyframes slide-out-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }
}