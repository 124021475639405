@media only screen and (max-width: 800px) {

    .user-profile{
        width: -webkit-fill-available !important;
        height: -webkit-fill-available !important;
        border-radius: 0 !important;
        border: none !important;
    }

    .user-prof-wrap{
        flex-direction: column-reverse!important;
    }

    .lfd{
        flex-direction: row!important;
    }

    .left-prof{
        border-top: 1px solid rgba(var(--white), .08)!important;
        padding: 20px!important;
    }

    .left-smd > svg{
        width: 18px!important;
        height: 18px!important;
    }

    .left-smd{
        padding: 12px!important;
    }

    .file-prev{
        max-height: none!important;
        min-height: 0!important;
        width: 30%!important;
        min-width: 30%!important;
    }

    .files-row{
        flex-direction: column!important;
        gap: 20px!important;
    }

    .filedata{
        width: -webkit-fill-available!important;
        flex-direction: row!important;
        gap: 20px!important;
    }

    .files-list{
        padding-bottom: 10%!important;
    }

    .scene-wrap{
        padding: 7%!important;
        padding-top: 10%!important;
    }

    .all-files-bar{
        position: unset!important;
        backdrop-filter: none !important;
    }

    .dsm-wrap{
        flex-direction: column!important;
    }

    .dwntask{
        flex-direction: column!important;
        align-items: flex-start!important;
        padding: 30px!important;
    }

    .dwntask-group{
        flex-direction: column!important;
        align-items: flex-start!important;
    }

    .dwntask > .dwntask-group > div > h1{
        width: 60%!important;
        word-wrap: break-word!important;
    }

    .user-prof-wrap{
        justify-content: space-between !important;
    }

}