@font-face {
    font-family: 'Inter';
    src: url('../../fonts/Inter-Regular.ttf');
}

@font-face {
    font-family: 'Inter Light';
    src: url('../../fonts/Inter-Light.ttf');
}

@font-face {
    font-family: 'Inter Medium';
    src: url('../../fonts/Inter-Medium.ttf');
}

@font-face {
    font-family: 'Inter Bold';
    src: url('../../fonts/Inter-Bold.ttf');
}

@font-face {
    font-family: 'Exo 2 Medium';
    src: url('../../fonts/Exo2-Medium.ttf');
}